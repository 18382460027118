import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// const navItems = [
//   "Datasets",
//   "Columns",
//   "Basic Infos",
//   "Components",
//   "Instructions",
//   "Samples",
//   "Download",
// ];

const navItems = ["Datasets", "Instructions", "Samples", "Publish"];

export default function Navigation() {
  const navigate = useNavigate();
  const handleNav = (item: string) => {
    navigate(`/${item.toLowerCase().replace(" ", "-")}`);
  };

  const location = useLocation();
  const path = location.pathname.split("/")[1];
  console.log("path", path);

  return (
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      {navItems.map((item) => (
        <Button
          key={item}
          sx={{ color: "#fff" }}
          onClick={() => {
            handleNav(item);
          }}
          variant={
            path === item.toLowerCase().replace(" ", "-") ? "outlined" : "text"
          }
        >
          {item}
        </Button>
      ))}
    </Box>
  );
}
