import { useEffect, useState } from "react";
import TopAppBar from "../../components/TopAppBar";
import {
  deleteSample,
  getSamples,
  updateSample,
} from "../../services/samplesService";
import { useRecoilState, useRecoilValue } from "recoil";
import { samplesAtom } from "../../atoms/samplesAtom";
import BasicTable from "../../components/BasicTable";
import {
  Box,
  Chip,
  Fab,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add, Dataset, Edit, Replay } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditableTextField from "../../components/EditableTextField";
import {
  addSampleBasicInfoDialogAtom,
  addSampleComponentDialogAtom,
  addSampleDatasetDialogAtom,
  addSampleDialogAtom,
} from "../../atoms/dialogsAtom";
import AddSampleBasicInfoDialog from "../../components/AddSampleBasicInfoDialog";
import AddSampleComponentDialog from "../../components/AddSampleComponentDialog";
import AddSampleDialog from "../../components/AddSampleDialog/AddSampleDialog";
import StyledTableCell from "../../components/StyledTableCell";
import BasicInfosChips from "./components/BasicInfosChips";
import ComponentsChips from "./components/ComponentsChips";
import { useLocation } from "react-router-dom";
import { get } from "http";
import { CohereClient } from "cohere-ai";
import generatePrompt from "../../utils/generatePrompt";
import DatasetsChips from "./components/DatasetsChips";
import AddSampleDatasetDialog from "../../components/AddSampleDatasetDialog";
import { currentDatasetAtom, datasetsAtom } from "../../atoms/datasetsAtom";
import { getCaptionForImage } from "../../services/getCaptionForImage";
import Prompt from "./components/Prompt";

export default function SamplesPage() {
  const theme = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(searchParams.get("page"));
  const currentDataset = useRecoilValue(currentDatasetAtom);

  console.log("Get samples current", page);

  const [samples, setSamples] = useRecoilState(samplesAtom);

  const [activeSample, setActiveSample] = useState<EntityWrapper<Sample>>();
  const [openAddBasicInfo, setOpenAddBasicInfo] = useRecoilState(
    addSampleBasicInfoDialogAtom
  );

  const [openAddDataset, setOpenAddDataset] = useRecoilState(
    addSampleDatasetDialogAtom
  );

  const [openAddComponent, setOpenAddComponent] = useRecoilState(
    addSampleComponentDialogAtom
  );
  const [openAddSample, setOpenAddSample] = useRecoilState(addSampleDialogAtom);
  const _getSamples = () => {
    console.log("Get samples", page);
    getSamples(
      parseInt(page ?? "1"),
      50,
      currentDataset == "all" ? [] : [currentDataset]
    )
      .then((data) => {
        console.log("Fetched samples", data);
        setSamples(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    // searchParams.set("page", "1");
    window.history.pushState(
      {},
      "",
      `${location.pathname}?${searchParams.toString()}`
    );
    setPage(searchParams.get("page"));

    _getSamples();
  }, [currentDataset]);

  const handleChangePage = (event: any, page: any) => {
    // Call your function here if needed
    getSamples(
      parseInt(page ?? "1"),
      50,
      currentDataset == "all" ? [] : [currentDataset]
    )
      .then((data) => {
        console.log("Fetched samples", data);
        setSamples(data);
        setPage(page);

        window.scrollTo({
          top: 0,
          behavior: "smooth", // Smooth scrolling
        });
      })
      .catch((error) => console.error(error));

    console.log("Page changed to:", page);

    // Update the URL without reloading the page
    searchParams.set("page", page);
    window.history.pushState(
      {},
      "",
      `${location.pathname}?${searchParams.toString()}`
    );
  };
  return (
    <div>
      <TopAppBar />

      <AddSampleDialog
        handleClose={() => setOpenAddSample(false)}
        handleAdd={() => {
          _getSamples();
          setOpenAddSample(false);
        }}
        open={openAddSample}
      />

      <AddSampleComponentDialog
        sample={activeSample}
        open={openAddComponent}
        handleClose={() => setOpenAddComponent(false)}
        handleAdd={(components: EntityWrapper<Component>[]) => {
          if (activeSample) {
            updateSample(
              activeSample!.id.toString(),
              undefined,
              undefined,
              undefined,
              [
                ...activeSample!.attributes.components.data.map((co) =>
                  co.id.toString()
                ),
                ...components.map((co) => co.id.toString()),
              ],
              activeSample.attributes.components_repeat
                ? [
                    ...activeSample!.attributes.components_repeat,
                    ...components.map((co) => {
                      return {
                        id: co.id,
                        repeat: 1,
                      };
                    }),
                  ]
                : components.map((co) => {
                    return {
                      id: co.id,
                      repeat: 1,
                    };
                  })
            ).then(() => {
              _getSamples();
            });
          }
          setOpenAddComponent(false);
        }}
      />

      <AddSampleBasicInfoDialog
        sample={activeSample}
        open={openAddBasicInfo}
        handleClose={() => setOpenAddBasicInfo(false)}
        handleAdd={(basicInfos: EntityWrapper<BasicInfo>[]) => {
          console.log("Selected basic infos", basicInfos);
          if (activeSample) {
            updateSample(activeSample!.id.toString(), undefined, undefined, [
              ...activeSample!.attributes.basic_infos.data.map((bi) =>
                bi.id.toString()
              ),
              ...basicInfos.map((bi) => bi.id.toString()),
            ]).then(() => {
              _getSamples();
            });
          }
          setOpenAddBasicInfo(false);
        }}
      />

      <AddSampleDatasetDialog
        sample={activeSample}
        open={openAddDataset}
        handleClose={() => setOpenAddDataset(false)}
        handleAdd={(datasets: EntityWrapper<Dataset>[]) => {
          if (activeSample) {
            updateSample(
              activeSample!.id.toString(),
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              [
                ...activeSample!.attributes.datasets.data.map((ds) =>
                  ds.id.toString()
                ),
                ...datasets.map((ds) => ds.id.toString()),
              ]
            ).then(() => {
              _getSamples();
            });
          }
          setOpenAddDataset(false);
        }}
      />

      <BasicTable
        columns={[
          { id: "id", content: "Id", width: "2%" },
          { id: "photo", content: "Photo", width: "20%" },
          // { id: "description", content: "Description", width: "15%" },
          { id: "datasets", content: "Datasets", width: "15%" },
          // { id: "basic_infos", content: "Basic Infos", width: "20%" },
          // { id: "components", content: "Components", width: "20%" },
          { id: "prompt", content: "Prompt", width: "20%" },
          { id: "actions", content: "Actions", width: "2%" },
        ]}
        // minWidth="2250px"
        heightSafe={128}
      >
        {samples?.data?.map((sample) => (
          <TableRow key={sample.id} hover>
            <StyledTableCell width={50}>{sample.id}</StyledTableCell>
            <StyledTableCell width={400}>
              <img
                src={sample.attributes.photo.data?.attributes.url ?? ""}
                alt={sample.attributes.description}
                width={"100%"}
                height={"auto"}
              />
            </StyledTableCell>
            {/* <StyledTableCell width={200}>
              <EditableTextField
                value={sample.attributes.description}
                onChange={(value) => {
                  console.log("Update sample", sample.id, value);
                  updateSample(sample.id.toString(), undefined, value).then(
                    () => {
                      _getSamples();
                    }
                  );
                }}
              />
            </StyledTableCell> */}
            <StyledTableCell width={200}>
              <DatasetsChips
                sample={sample}
                _getSamples={_getSamples}
                setOpenAddDataset={setOpenAddDataset}
                setActiveSample={setActiveSample}
              />
            </StyledTableCell>
            {/* <StyledTableCell width={200}>
              <BasicInfosChips
                sample={sample}
                _getSamples={_getSamples}
                setOpenAddBasicInfo={setOpenAddBasicInfo}
                setActiveSample={setActiveSample}
              />
            </StyledTableCell> */}
            {/* <StyledTableCell width={500}>
              <ComponentsChips
                sample={sample}
                _getSamples={_getSamples}
                setOpenAddComponent={setOpenAddComponent}
                setActiveSample={setActiveSample}
              />
            </StyledTableCell> */}
            <StyledTableCell
              width={400}
              backgroundColor={
                sample.attributes.prompt ? colors.green[900] : colors.red[900]
              }
            >
              <Prompt sample={sample} _getSamples={_getSamples} />
            </StyledTableCell>
            <StyledTableCell width={50}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    console.log("Delete sample", sample.id);
                    deleteSample(sample.id.toString()).then(() => {
                      _getSamples();
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </StyledTableCell>
          </TableRow>
        ))}
      </BasicTable>

      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          setOpenAddSample(true);
        }}
      >
        <AddIcon />
      </Fab>

      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Box
          sx={{
            width: "100%",
            paddingLeft: 2,
          }}
        >
          <Typography variant="caption">
            Total # of Samples: {samples?.meta?.pagination.total} | # of Samples
            in this Page: {samples?.data?.length} | # of captioned Samples in
            this page:{" "}
            {samples?.data?.reduce(
              (count, sample) =>
                sample.attributes.prompt != null ? count + 1 : count,
              0
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={samples?.meta?.pagination.pageCount}
            color="primary"
            page={parseInt(page ?? "1")}
            onChange={handleChangePage}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          {/* <Typography>Page: {page}</Typography> */}
        </Box>
      </Box>
    </div>
  );
}
