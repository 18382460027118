import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getDataset, postDataset } from "../services/datasetService";
import { useRecoilState } from "recoil";
import { addDatasetDialogAtom } from "../atoms/dialogsAtom";
import AddDatasetDialog from "./AddDatasetDialog";
import { currentDatasetAtom, datasetsAtom } from "../atoms/datasetsAtom";

export default function SelectDataset() {
  // const [selectedDataset, setSelectedDataset] = React.useState("");
  const [datasets, setDatasets] = useRecoilState(datasetsAtom);
  const [open, setOpen] = useRecoilState(addDatasetDialogAtom);
  const [currentDataset, setCurrentDataset] =
    useRecoilState(currentDatasetAtom);

  const _getDatasets = async () => {
    try {
      const response = await getDataset();
      setDatasets(response);
      if (currentDataset != "") {
        return;
      }
      setCurrentDataset("all");
      // setCurrentDataset(response.data[0].id.toString());
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    _getDatasets();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentDataset(event.target.value);
  };

  if (!datasets?.data?.length) {
    return null;
  }
  return (
    <Box sx={{ minWidth: 300 }}>
      <AddDatasetDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleAdd={(name: string) => {
          postDataset(name)
            .then(() => {
              _getDatasets();
              setOpen(false);
            })
            .catch((error) => console.error(error));
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Dataset</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentDataset}
          label="Dataset"
          onChange={handleChange}
          size="small"
        >
          <MenuItem value="all">All Datasets</MenuItem>

          {datasets.data.map((dataset) => (
            <MenuItem value={dataset.id} key={dataset.id}>
              {dataset.attributes.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
