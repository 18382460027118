import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Button, CssBaseline } from "@mui/material";
import TopAppBar from "./components/TopAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import BasicTabs from "./components/BasicTabs";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ColumnsPage from "./pages/columns/ColumnsPage";
import BasicInfosPage from "./pages/basic_infos/BasicInfosPage";
import ComponentsPage from "./pages/components/ComponentsPage";
import SamplesPage from "./pages/samples/SamplesPage";
import { RecoilRoot } from "recoil";
import DatasetsPage from "./pages/datasets/DatasetsPage";
import { Download } from "@mui/icons-material";
import DownloadPage from "./pages/download/Download";
import InstructionsPage from "./pages/instructions/InstructionsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SamplesPage />,
  },
  {
    path: "/columns",
    element: <ColumnsPage />,
  },
  {
    path: "/basic-infos",
    element: <BasicInfosPage />,
  },
  {
    path: "/components",
    element: <ComponentsPage />,
  },
  {
    path: "/samples",
    element: <SamplesPage />,
  },
  {
    path: "/datasets",
    element: <DatasetsPage />,
  },
  {
    path: "/publish",
    element: <DownloadPage />,
  },
  {
    path: "/instructions",
    element: <InstructionsPage />,
  },
]);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
        {/* <BasicTabs /> */}
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
